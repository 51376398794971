import React, { ReactEventHandler, useState } from "react";
import styles from "./Fotter.module.scss";
import { ReactComponent as TG } from "./tg.svg";
import { ReactComponent as X } from "./x.svg";

function Fotter() {
  return (
    <div className={styles.Fotter}>
      <div className={`container ${styles.FotterContainer}`}>
        <div className={`${styles.FotterLinks}`}>
          <a className="nav-link me-4" href="">
            White paper
          </a>
          <a className="nav-link me-4" href="">
            Gitbook
          </a>
          <a className="nav-link me-4" href="">
            Admin
          </a>
          <a className="nav-link me-4" href="">
            Job
          </a>
        </div>
        <div className={`${styles.FotterLinks}`}>
          <a
            className="nav-link me-4"
            href="https://t.me/enchanted_world"
            rel="noreferrer"
            target="_blank"
          >
            <TG />
          </a>
          <a
            className="nav-link me-4"
            href="https://twitter.com/EnchWorld"
            rel="noreferrer"
            target="_blank"
          >
            <X />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Fotter;
