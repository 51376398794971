const DICTIONARY: any = {
  "2024q21": {
    ch: "<span>第二季度</span> 预售，开发白皮书和游戏的主要概念。",
    en: "<span>Q2</span> Presale, development of white paper and main game concept.",
    ge: "<span>Q2</span> Vorverkauf, Entwicklung des Whitepapers und Hauptspielkonzepts.",
    jpn: "<span>第2四半期</span> プレセール、ホワイトペーパーの開発、およびゲームの主要コンセプト。",
    ru: "<span>2й квартал</span> Предпродажа, разработка white paper и основной концепции игры.",
    skor: "<span>2분기</span> 프리세일, 백서 개발 및 게임 주요 개념 개발.",
    uae: "<span>الربع الثاني</span> البيع المسبق، تطوير الورقة البيضاء والمفهوم الرئيسي للعبة.",
  },
  "2024q22": {
    ch: "<span>第二季度</span> 在 ston.fi 上启动交易",
    en: "<span>Q2</span> Launch trading on ston.fi",
    ge: "<span>Q2</span> Handel auf ston.fi starten",
    jpn: "<span>第2四半期</span> ston.fiで取引を開始",
    ru: "<span>2й квартал</span> Запуск торгов на ston.fi",
    skor: "<span>2분기</span> ston.fi에서 거래 시작",
    uae: "<span>الربع الثاني</span> إطلاق التداول على ston.fi",
  },
  "2024q3": {
    ch: "<span>第三季度</span> NFT 销售和空投给 NFT 持有者",
    en: "<span>Q3</span> NFT sales and airdrop for NFT holders",
    ge: "<span>Q3</span> NFT-Verkäufe und Airdrop für NFT-Inhaber",
    jpn: "<span>第3四半期</span> NFTの販売とNFT所有者へのエアドロップ",
    ru: "<span>3й квартал</span> Продажи NFT и airdrop для держателей NFT",
    skor: "<span>3분기</span> NFT 판매 및 NFT 보유자 에어드롭",
    uae: "<span>الربع الثالث</span> مبيعات NFT والإسقاط الجوي لحاملي NFT",
  },
  "2024q4": {
    ch: "<span>第四季度</span> 完整测试游戏，集成TON，PVP系统和在TON测试环境中分发奖励",
    en: "<span>Q4</span> Full game testing with TON integration, PvP system, and reward distribution in TON test environment",
    ge: "<span>Q4</span> Vollständige Spieltests mit TON-Integration, PvP-System und Belohnungsverteilung in der TON-Testumgebung",
    jpn: "<span>第4四半期</span> TON統合、PvPシステム、およびTONテスト環境での報酬分配を伴う完全なゲームテスト",
    ru: "<span>4й квартал</span> Полное тестирование игры с интеграцией TON, ПВП системой и распределением наград в тестовой среде TON",
    skor: "<span>4분기</span> TON 통합, PvP 시스템 및 TON 테스트 환경에서 보상 분배와 함께 전체 게임 테스트",
    uae: "<span>الربع الرابع</span> اختبار كامل للعبة مع تكامل TON، نظام PvP، وتوزيع المكافآت في بيئة اختبار TON",
  },
  "2025q1": {
    ch: "<span>第一-第二季度</span> 在TON中启动游戏",
    en: "<span>Q1-Q2</span> Game launch in TON",
    ge: "<span>Q1-Q2</span> Spielstart in TON",
    jpn: "<span>第1-2四半期</span> TONでのゲームの開始",
    ru: "<span>1й-2й квартал</span> Запуск игры в TON",
    skor: "<span>1-2분기</span> TON에서 게임 출시",
    uae: "<span>الربع الأول-الثاني</span> إطلاق اللعبة في TON",
  },
  "2025q2": {
    ch: "<span>第二-第四季度</span> 游戏和游戏平台的进一步发展",
    en: "<span>Q2-Q4</span> Further development of the game and gaming platform",
    ge: "<span>Q2-Q4</span> Weiterentwicklung des Spiels und der Spielplattform",
    jpn: "<span>第2-4四半期</span> ゲームおよびゲームプラットフォームのさらなる開発",
    ru: "<span>2й-4й квартал</span> Дальнейшее развитие игры и игровой платформы",
    skor: "<span>2-4분기</span> 게임 및 게임 플랫폼의 추가 개발",
    uae: "<span>الربع الثاني-الرابع</span> مزيد من تطوير اللعبة ومنصة الألعاب",
  },
  about: {
    ch: "关于游戏",
    en: "About the Game",
    ge: "Über das Spiel",
    jpn: "ゲームについて",
    ru: "Об игре",
    skor: "게임 정보",
    uae: "عن اللعبة",
  },
  adventure: {
    ch: "冒险",
    en: "Adventure",
    ge: "Abenteuer",
    jpn: "冒険",
    ru: "Приключения",
    skor: "모험",
    uae: "مغامرة",
  },
  adventureDescription: {
    ch: "有20个等级供角色升级，每个等级有多个地点，难度各异。难度越高，掉落物品的概率和获得的经验越高。玩家必须在<span>残酷难度</span>下完成所有地下城才能进入下一阶段。",
    en: "There are 20 levels for character progression, each with multiple locations of varying difficulty. The higher the difficulty, the better the item drop rates and experience gained. To advance to the next stage, players must complete all dungeons on <span>brutal difficulty</span>.",
    ge: "Es gibt 20 Level zur Charakterentwicklung, jedes mit mehreren Orten unterschiedlicher Schwierigkeit. Je höher die Schwierigkeit, desto besser die Gegenstandsdrops und die Erfahrung. Um zum nächsten Abschnitt zu gelangen, müssen alle Dungeons auf <span>brutalem Schwierigkeitsgrad</span> abgeschlossen werden.",
    jpn: "キャラクターの成長には20のレベルがあり、それぞれに難易度の異なる複数の場所があります。難易度が高いほど、アイテムのドロップ率と獲得経験値が高くなります。次のステージに進むには、すべてのダンジョンを<span>残酷な難易度</span>でクリアする必要があります。",
    ru: "Существует 20 уровней для прокачки персонажа, каждый уровень имеет несколько локаций с разными уровнями сложностей. От уровня сложности зависит вероятность выпадения предметов и получаемый опыт. Чтобы перейти на следующий этап, необходимо пройти все подземелья на <span>брутальной сложности</span>.",
    skor: "캐릭터 성장을 위한 20개의 레벨이 있으며, 각 레벨에는 다양한 난이도의 여러 장소가 있습니다. 난이도가 높을수록 아이템 드롭률과 경험치가 높아집니다. 다음 단계로 넘어가려면 모든 던전을 <span>잔인한 난이도</span>로 완료해야 합니다.",
    uae: "هناك 20 مستوى لتطوير الشخصية، كل مستوى يحتوي على عدة مواقع بدرجات صعوبة مختلفة. كلما زادت الصعوبة، زادت احتمالية سقوط العناصر والخبرة المكتسبة. للانتقال إلى المرحلة التالية، يجب على اللاعب إكمال جميع الأبراج المحصنة على <span>صعوبة وحشية</span>.",
  },
  buy: {
    ch: "购买",
    en: "Buy",
    ge: "Kaufen",
    jpn: "購入",
    ru: "Купить",
    skor: "구매",
    uae: "شراء",
  },
  comingSoon: {
    ch: "即将推出",
    en: "Coming Soon",
    ge: "Kommt bald",
    jpn: "近日公開",
    ru: "Скоро открытие",
    skor: "곧 출시 예정",
    uae: "قريبا",
  },
  countPlayers: {
    ch: "玩家数量",
    en: "Number of Players",
    ge: "Anzahl der Spieler",
    jpn: "プレイヤー数",
    ru: "Кол-во игроков",
    skor: "플레이어 수",
    uae: "عدد اللاعبين",
  },
  demo: {
    ch: "游戏演示",
    en: "Game Demo",
    ge: "Spiel-Demo",
    jpn: "ゲームデモ",
    ru: "Демо игры",
    skor: "게임 데모",
    uae: "عرض تجريبي للعبة",
  },
  description_airdrop_rewards: {
    ch: "将用作空投和各种奖励。为项目的发展提供帮助。",
    en: "Will be used for airdrop and various rewards. For helping in the development of the project.",
    ge: "Wird für Airdrop und verschiedene Belohnungen verwendet. Für die Unterstützung bei der Entwicklung des Projekts.",
    jpn: "エアドロップやさまざまな報酬として使用されます。プロジェクトの開発に協力するために。",
    ru: "Будет использоваться в качестве airdrop и различных наград. За помощь в развитии проекта.",
    skor: "에어드롭 및 다양한 보상으로 사용될 예정입니다. 프로젝트 개발을 돕기 위해.",
    uae: "سيتم استخدامه للإسقاط الجوي والمكافآت المختلفة. لمساعدة في تطوير المشروع.",
  },
  description_future_development: {
    ch: "将用于游戏和平台整体的进一步发展，我们计划在一个网络中启动多个项目。",
    en: "Will be used for further development of the game and the platform as a whole, we plan to launch several projects in one network.",
    ge: "Wird für die Weiterentwicklung des Spiels und der Plattform insgesamt verwendet, wir planen, mehrere Projekte in einem Netzwerk zu starten.",
    jpn: "ゲームおよびプラットフォーム全体のさらなる開発に使用される予定です。私たちは一つのネットワークでいくつかのプロジェクトを立ち上げる予定です。",
    ru: "Будет использоваться для дальнейшего развития игры и платформы в целом, мы планируем запускать несколько проектов в одной сети.",
    skor: "게임 및 플랫폼의 전체적인 추가 개발에 사용될 예정이며, 우리는 하나의 네트워크에서 여러 프로젝트를 시작할 계획입니다.",
    uae: "سيتم استخدامه لمزيد من تطوير اللعبة والمنصة ككل، نحن نخطط لإطلاق عدة مشاريع في شبكة واحدة.",
  },
  description_in_game_rewards: {
    ch: "将用于游戏内，每个玩家都有机会获得 ENCH 代币奖励。",
    en: "Will be used inside the game, each player has a chance to receive a reward in ENCH token.",
    ge: "Wird im Spiel verwendet, jeder Spieler hat die Chance, eine Belohnung im ENCH-Token zu erhalten.",
    jpn: "ゲーム内で使用され、各プレイヤーはENCHトークンの報酬を受け取るチャンスがあります。",
    ru: "Будет использоваться внутри игры, каждый игрок имеет шанс получить награду в токене ENCH.",
    skor: "게임 내에서 사용되며, 각 플레이어는 ENCH 토큰으로 보상을 받을 기회를 가집니다.",
    uae: "سيتم استخدامه داخل اللعبة، كل لاعب لديه فرصة للحصول على مكافأة في رمز ENCH.",
  },
  description_presale_investors: {
    ch: "将出售给参与预售的投资者。",
    en: "Will be sold to investors who participate in the presale.",
    ge: "Wird an Investoren verkauft, die am Vorverkauf teilnehmen.",
    jpn: "プレセールに参加する投資家に販売されます。",
    ru: "Будет продано инвесторам, которые участвуют в presale.",
    skor: "프리세일에 참여하는 투자자에게 판매될 예정입니다.",
    uae: "سيتم بيعها للمستثمرين الذين يشاركون في البيع المسبق.",
  },
  dividendAndTokenInfoDescription: {
    ch: "游戏中的主要支付加密货币是TON，但也有一个名为<span>ENCH</span>的代币。每周将分配<span>1%</span>（游戏账户总金额的）给玩家。玩家还可以通过各种游戏活动和成就获得<span>ENCH</span>代币，例如地下城排名、竞技场和PVP。所有积分将被汇总，每周分配给所有玩家。",
    en: "The main cryptocurrency used in the game is TON, but there is also a token called <span>ENCH</span>. <span>1%</span> of the total TON in the game's account will be distributed weekly among players. Players can also earn <span>ENCH</span> tokens for various actions and achievements in the game, such as dungeon rankings, arena, and PVP. All points will be summed up and tokens will be distributed weekly among all players.",
    ge: "Die Hauptkryptowährung im Spiel ist TON, aber es gibt auch einen Token namens <span>ENCH</span>. <span>1%</span> des gesamten TON auf dem Spielkonto wird wöchentlich unter den Spielern verteilt. Spieler können auch <span>ENCH</span>-Token für verschiedene Aktionen und Erfolge im Spiel verdienen, wie z.B. Dungeon-Rankings, Arena und PVP. Alle Punkte werden summiert und die Token wöchentlich unter allen Spielern verteilt.",
    jpn: "ゲーム内で使用される主要な暗号通貨はTONですが、<span>ENCH</span>というトークンもあります。ゲームアカウントの総TONの<span>1%</span>が毎週プレイヤーに分配されます。プレイヤーはダンジョンランキング、アリーナ、PVPなどのさまざまなアクションや実績に対して<span>ENCH</span>トークンを獲得することもできます。すべてのポイントが集計され、トークンは毎週すべてのプレイヤーに分配されます。",
    ru: "Основной платежеспособной криптовалютой в игре является TON, однако, существует токен, который также используется в игре, а именно токен<span> ENCH</span>. <span>1%</span> (от общей суммы TON на счете игры) будет еженедельно распределяться между игроками. Также токен <span>ENCH</span> можно получать за различные действия и достижения в игре, например за рейтинг в прохождении подземелья, за арену и пвп. Все очки будут суммироваться и еженедельно токены будут распределяться между всеми игроками.",
    skor: "게임에서 사용되는 주요 암호화폐는 TON이지만, <span>ENCH</span>라는 토큰도 있습니다. 게임 계정의 총 TON의 <span>1%</span>가 매주 플레이어에게 분배됩니다. 플레이어는 던전 랭킹, 아레나, PVP와 같은 다양한 행동과 업적에 대해 <span>ENCH</span> 토큰을 얻을 수 있습니다. 모든 포인트가 합산되어 토큰이 매주 모든 플레이어에게 분배됩니다.",
    uae: "العملة المشفرة الرئيسية المستخدمة في اللعبة هي TON، ولكن هناك أيضًا رمز يسمى <span>ENCH</span>. سيتم توزيع <span>1%</span> من إجمالي TON في حساب اللعبة أسبوعيًا بين اللاعبين. يمكن للاعبين أيضًا كسب رموز <span>ENCH</span> مقابل مختلف الإجراءات والإنجازات في اللعبة، مثل تصنيفات الأبراج المحصنة، الساحة، وPVP. سيتم جمع جميع النقاط وتوزيع الرموز أسبوعيًا بين جميع اللاعبين.",
  },
  dividendAndTokenInfoTitle: {
    ch: "股息和ENCH代币",
    en: "Dividends and ENCH Token",
    ge: "Dividenden und ENCH-Token",
    jpn: "配当とENCHトークン",
    ru: "Дивиденды и токен ENCH",
    skor: "배당금 및 ENCH 토큰",
    uae: "الأرباح ورمز ENCH",
  },
  from: {
    ch: "来自",
    en: "from",
    ge: "von",
    jpn: "から",
    ru: "от",
    skor: "에서",
    uae: "من",
  },
  interface: {
    ch: "游戏界面",
    en: "Game Interface",
    ge: "Spieloberfläche",
    jpn: "ゲームインターフェース",
    ru: "Интерфейс игры",
    skor: "게임 인터페이스",
    uae: "واجهة اللعبة",
  },
  interfaceDescOne: {
    ch: "游戏设计采用2D像素艺术RPG，界面简单易用。游戏的主要理念是让你不必花费大量时间。你进入游戏，执行特定操作，消耗能量并获得奖励。",
    en: "The game design features 2D pixel art RPG with a simple and user-friendly interface. The main idea of the game is to not make you spend too much time. You enter the game, perform certain actions, spend your energy, and receive rewards for your actions.",
    ge: "Das Spieldesign bietet 2D-Pixel-Art-RPG mit einer einfachen und benutzerfreundlichen Oberfläche. Die Hauptidee des Spiels ist es, nicht zu viel Zeit zu beanspruchen. Du betrittst das Spiel, führst bestimmte Aktionen aus, verbrauchst deine Energie und erhältst Belohnungen für deine Aktionen.",
    jpn: "ゲームデザインは2DピクセルアートRPGで、シンプルで使いやすいインターフェースを特徴としています。ゲームの主なアイデアは、あまり時間をかけさせないことです。ゲームに入り、特定のアクションを実行し、エネルギーを消費して、アクションに対する報酬を受け取ります。",
    ru: "Дизайн игры представляет 2D пиксельную RPG с простым и удобным интерфейсом. Основная идея игры - не заставлять вас тратить много времени. Вы заходите в игру, выполняете определенные действия, тратите свою энергию и получаете бонусы за свои действия.",
    skor: "게임 디자인은 간단하고 사용자 친화적인 인터페이스를 갖춘 2D 픽셀 아트 RPG입니다. 게임의 주요 아이디어는 너무 많은 시간을 소비하지 않도록 하는 것입니다. 게임에 들어가 특정 행동을 수행하고 에너지를 소비하며 행동에 대한 보상을 받습니다.",
    uae: "تصميم اللعبة يتميز بفن البكسل ثنائي الأبعاد مع واجهة بسيطة وسهلة الاستخدام. الفكرة الرئيسية للعبة هي عدم جعلك تقضي الكثير من الوقت. تدخل اللعبة، تقوم بأداء إجراءات معينة، تنفق طاقتك، وتحصل على مكافآت مقابل أفعالك.",
  },
  interfaceDescTwo: {
    ch: "简单方便的角色控制，地下城探险，PVP，与世界各地的玩家交流，促进社交技能的发展，让我们一起成为朋友。",
    en: "Simple and convenient character control, dungeon raids, PVP, communication with players from around the world, fostering social skills development. Let's all be friends.",
    ge: "Einfache und bequeme Charaktersteuerung, Dungeon-Raids, PVP, Kommunikation mit Spielern aus aller Welt, Förderung der sozialen Fähigkeiten. Lasst uns alle Freunde sein.",
    jpn: "シンプルで便利なキャラクターコントロール、ダンジョンレイド、PVP、世界中のプレイヤーとのコミュニケーション、社会的スキルの発展を促進します。みんな友達になりましょう。",
    ru: "Простое и удобное управление персонажем, походы в данж, ПВП, общение с игроками со всего мира, что способствует развитию социальных навыков. Давайте все дружить.",
    skor: "간단하고 편리한 캐릭터 제어, 던전 레이드, PVP, 전 세계 플레이어와의 소통, 사회적 기술 개발 촉진. 모두 친구가 됩시다.",
    uae: "تحكم بسيط ومريح في الشخصية، غارات الأبراج المحصنة، PVP، التواصل مع اللاعبين من جميع أنحاء العالم، تعزيز تطوير المهارات الاجتماعية. دعونا نكون جميعًا أصدقاء.",
  },
  nextDevelopment: {
    ch: "游戏和游戏平台的进一步发展",
    en: "Further development of the game and gaming platform",
    ge: "Weiterentwicklung des Spiels und der Spielplattform",
    jpn: "ゲームおよびゲームプラットフォームのさらなる開発",
    ru: "Дальнейшее развитие игры и игровой платформы",
    skor: "게임 및 게임 플랫폼의 추가 개발",
    uae: "مزيد من تطوير اللعبة ومنصة الألعاب",
  },
  oborot: {
    ch: "游戏周转",
    en: "Game Turnover",
    ge: "Spielumsatz",
    jpn: "ゲームの売上",
    ru: "Оборот игры",
    skor: "게임 매출",
    uae: "دوران اللعبة",
  },
  playerAttr: {
    ch: "角色属性",
    en: "Character Attributes",
    ge: "Charakterattribute",
    jpn: "キャラクター属性",
    ru: "Характеристики персонажа",
    skor: "캐릭터 속성",
    uae: "سمات الشخصية",
  },
  playerAttrDescription: {
    ch: '每个角色都有基本属性，包括<span>力量</span>、<span>敏捷</span>和<span>智力</span>。每升一级，玩家会获得"经验点"，可以自行分配。力量影响武器伤害、暴击伤害、暴击几率和生命值。敏捷影响闪避、攻击速度和防御。智力影响技能伤害和法力值。通过提升角色的基本属性，角色可以获得<span>特定技能</span>，这些技能可以在PVP或其他战斗中显著改变游戏进程。',
    en: 'Each character has basic attributes: <span>strength</span>, <span>agility</span>, and <span>intelligence</span>. With each new level, the player receives "experience points" to distribute. Strength affects weapon damage, critical damage, critical hit chance, and HP. Agility affects dodge, attack speed, and defense. Intelligence affects skill damage and MP. By developing the character’s basic attributes, the character gains <span>specific skills</span> that can significantly change the course of the game during PVP or other battles.',
    ge: 'Jeder Charakter hat grundlegende Attribute: <span>Stärke</span>, <span>Beweglichkeit</span> und <span>Intelligenz</span>. Mit jedem neuen Level erhält der Spieler "Erfahrungspunkte" zur Verteilung. Stärke beeinflusst Waffenschaden, kritischen Schaden, kritische Trefferchance und HP. Beweglichkeit beeinflusst Ausweichen, Angriffsgeschwindigkeit und Verteidigung. Intelligenz beeinflusst Fertigkeitsschaden und MP. Durch die Entwicklung der grundlegenden Attribute des Charakters erhält der Charakter <span>bestimmte Fähigkeiten</span>, die den Verlauf des Spiels während PVP oder anderen Kämpfen erheblich verändern können.',
    jpn: "各キャラクターには基本的な属性があり、それは<span>力</span>、<span>敏捷性</span>、<span>知性</span>です。新しいレベルごとに、プレイヤーは「経験ポイント」を受け取り、それを分配します。力は武器のダメージ、クリティカルダメージ、クリティカルヒットの確率、HPに影響します。敏捷性は回避、攻撃速度、防御に影響します。知性はスキルダメージとMPに影響します。キャラクターの基本属性を発展させることで、キャラクターは<span>特定のスキル</span>を獲得し、PVPや他の戦闘中にゲームの進行を大きく変えることができます。",
    ru: 'Каждый персонаж обладает основными характеристиками: <span>сила</span>, <span>ловкость</span> и <span>интеллект</span>. С каждым новым уровнем игрок получает "Очки опыта", которые можно распределить. Сила влияет на урон оружием, критический урон, шанс критического удара и количество HP. Ловкость влияет на уклонение, скорость атаки и защиту. Интеллект влияет на урон от навыков и количество MP. Развивая основные характеристики персонажа, персонаж получает <span>определенные навыки</span>, которые могут значительно изменить ход игры во время PVP или других сражений.',
    skor: '각 캐릭터는 기본 속성을 가지고 있습니다: <span>힘</span>, <span>민첩성</span>, <span>지능</span>. 각 새로운 레벨마다 플레이어는 "경험치 포인트"를 받아 분배합니다. 힘은 무기 피해, 치명타 피해, 치명타 확률 및 HP에 영향을 미칩니다. 민첩성은 회피, 공격 속도 및 방어에 영향을 미칩니다. 지능은 스킬 피해 및 MP에 영향을 미칩니다. 캐릭터의 기본 속성을 개발함으로써, 캐릭터는 PVP 또는 다른 전투 중에 게임의 흐름을 크게 바꿀 수 있는 <span>특정 스킬</span>을 얻습니다.',
    uae: 'كل شخصية لديها سمات أساسية: <span>القوة</span>، <span>المرونة</span>، و<span>الذكاء</span>. مع كل مستوى جديد، يحصل اللاعب على "نقاط الخبرة" لتوزيعها. تؤثر القوة على ضرر السلاح، الضرر الحرج، فرصة الضربة الحرجة، وHP. تؤثر المرونة على المراوغة، سرعة الهجوم، والدفاع. يؤثر الذكاء على ضرر المهارات وMP. من خلال تطوير السمات الأساسية للشخصية، تكتسب الشخصية <span>مهارات محددة</span> يمكن أن تغير بشكل كبير مسار اللعبة أثناء PVP أو المعارك الأخرى.',
  },
  pvpArenaBosses: {
    ch: "PVP / 竞技场 / Boss",
    en: "PVP / Arena / Bosses",
    ge: "PVP / Arena / Bosse",
    jpn: "PVP / アリーナ / ボス",
    ru: "ПВП / Арена / Боссы",
    skor: "PVP / 아레나 / 보스",
    uae: "PVP / الساحة / الرؤساء",
  },
  pvpArenaBossesDescription: {
    ch: "游戏中存在PVP系统，在寻宝过程中有可能会遇到其他玩家，每个玩家可以决定是否战斗或避免冲突。敌人越强大，玩家获得的奖励就越多。<span>角色死亡时也有可能掉落物品。</span>",
    en: "The game features a PVP system where players may encounter each other while treasure hunting. Each player can decide whether to fight or avoid the conflict. The stronger the opponent, the greater the rewards. <span>There is also a chance of item drops upon character death.</span>",
    ge: "Im Spiel gibt es ein PVP-System, bei dem Spieler auf Schatzsuche aufeinandertreffen können. Jeder Spieler kann entscheiden, ob er kämpfen oder den Konflikt vermeiden möchte. Je stärker der Gegner, desto größer die Belohnungen. <span>Es besteht auch die Möglichkeit, dass Gegenstände beim Tod des Charakters fallen gelassen werden.</span>",
    jpn: "ゲームにはPVPシステムがあり、宝探し中に他のプレイヤーと遭遇する可能性があります。各プレイヤーは戦うか衝突を避けるかを決定できます。敵が強ければ強いほど、プレイヤーはより多くのボーナスを獲得します。<span>キャラクターが死亡した場合、アイテムがドロップする可能性もあります。</span>",
    ru: "В игре существует система ПВП, при охоте за сокровищами есть вероятность столкновения двух и более игроков. Каждый игрок принимает решение, стоит ли ему сражаться или стоит избежать столкновения. Чем сильнее противник, тем больше бонусов игрок получает. <span>Также существует вероятность выпадения предмета при гибели персонажа.</span>",
    skor: "게임에는 PVP 시스템이 있으며, 보물 사냥 중에 다른 플레이어와 마주칠 가능성이 있습니다. 각 플레이어는 싸울지 충돌을 피할지 결정할 수 있습니다. 상대가 강할수록 플레이어는 더 많은 보너스를 받습니다. <span>캐릭터가 죽으면 아이템이 떨어질 가능성도 있습니다.</span>",
    uae: "توجد في اللعبة نظام PVP، حيث يمكن أن يواجه اللاعبون بعضهم البعض أثناء البحث عن الكنوز. يمكن لكل لاعب أن يقرر ما إذا كان سيقاتل أو يتجنب الصراع. كلما كان الخصم أقوى، زادت المكافآت التي يحصل عليها اللاعب. <span>هناك أيضًا احتمال سقوط العناصر عند وفاة الشخصية.</span>",
  },
  roadmap: {
    ch: "路线图",
    en: "Roadmap",
    ge: "Fahrplan",
    jpn: "ロードマップ",
    ru: "Дорожная карта",
    skor: "로드맵",
    uae: "خريطة الطريق",
  },
  startGame: {
    ch: "开始游戏",
    en: "Start Game",
    ge: "Spiel starten",
    jpn: "ゲームを始める",
    ru: "Начать игру",
    skor: "게임 시작",
    uae: "ابدأ اللعبة",
  },
  startPlayNowText: {
    ch: "现在开始玩吧！",
    en: "Let's play now!",
    ge: "Lasst uns jetzt spielen!",
    jpn: "今すぐプレイしよう！",
    ru: "Давайте играть сейчас!",
    skor: "지금 플레이하자!",
    uae: "لنلعب الآن!",
  },
  title: {
    ch: "最令人兴奋的MMORPG游戏！",
    en: "The most exciting MMORPG game!",
    ge: "Das aufregendste MMORPG-Spiel!",
    jpn: "最もエキサイティングなMMORPGゲーム！",
    ru: "Увлекательнейшая MMORPG игра!",
    skor: "가장 흥미로운 MMORPG 게임!",
    uae: "أكثر لعبة MMORPG إثارة!",
  },
  titleDescription: {
    ch: "带有P2E和PVP元素的MMORPG，发展你的角色，与其他玩家竞争。与怪物战斗，击败世界boss，参加泰坦之战，创造独特的物品并从中获利，所有这些以及更多内容都在TON生态系统中！",
    en: "MMORPG with P2E and PVP elements. Develop your character, compete with other players. Fight monsters, defeat world bosses, participate in the battle of titans, create unique items and earn from it. All this and much more in the TON ecosystem!",
    ge: "MMORPG mit P2E- und PVP-Elementen. Entwickle deinen Charakter, konkurriere mit anderen Spielern. Kämpfe gegen Monster, besiege Weltbosse, nimm an der Schlacht der Titanen teil, erstelle einzigartige Gegenstände und verdiene damit. All dies und vieles mehr im TON-Ökosystem!",
    jpn: "P2EとPVP要素を備えたMMORPG。キャラクターを育成し、他のプレイヤーと競い合いましょう。モンスターと戦い、世界のボスを倒し、タイタンの戦いに参加し、ユニークなアイテムを作成して収益を得ましょう。これらすべてがTONエコシステムにあります！",
    ru: "MMORPG с элементами P2E и PVP. Развивай своего персонажа, соревнуйся с другими игроками. Сражайся с монстрами, побеждай мировых боссов, принимай участие в битве титанов, создавай уникальные предметы и зарабатывай на этом. Все это и многое другое в экосистеме TON!",
    skor: "P2E 및 PVP 요소가 포함된 MMORPG. 캐릭터를 개발하고 다른 플레이어와 경쟁하세요. 몬스터와 싸우고, 월드 보스를 물리치고, 타이탄 전투에 참여하고, 독특한 아이템을 만들고 수익을 올리세요. 이 모든 것과 더 많은 것이 TON 생태계에 있습니다!",
    uae: "MMORPG مع عناصر P2E وPVP. طوّر شخصيتك، نافس اللاعبين الآخرين. قاتل الوحوش، اهزم زعماء العالم، شارك في معركة الجبابرة، أنشئ عناصر فريدة واكسب منها. كل هذا وأكثر في نظام TON البيئي!",
  },
  tokenomics: {
    ch: "代币经济学",
    en: "Tokenomics",
    ge: "Tokenomics",
    jpn: "トークノミクス",
    ru: "Токеномика",
    skor: "토크노믹스",
    uae: "اقتصاديات الرمز",
  },
  tokenomicsAirdrop: {
    ch: "将用于空投和各种奖励。帮助项目发展。",
    en: "Will be used for airdrops and various rewards. For helping in the development of the project.",
    ge: "Wird für Airdrops und verschiedene Belohnungen verwendet. Für die Unterstützung bei der Entwicklung des Projekts.",
    jpn: "エアドロップやさまざまな報酬に使用されます。プロジェクトの発展を支援するため。",
    ru: "Будет использоваться в качестве airdrop и различных наград. За помощь в развитии проекта.",
    skor: "에어드롭 및 다양한 보상에 사용됩니다. 프로젝트 개발을 돕기 위해.",
    uae: "سيتم استخدامه للإسقاطات الجوية والمكافآت المختلفة. للمساعدة في تطوير المشروع.",
  },
  tokenomicsGame: {
    ch: "将在游戏中使用，每个玩家都有机会获得ENCH代币奖励。",
    en: "Will be used in-game, each player has a chance to receive a reward in ENCH tokens.",
    ge: "Wird im Spiel verwendet, jeder Spieler hat die Chance, eine Belohnung in ENCH-Token zu erhalten.",
    jpn: "ゲーム内で使用され、各プレイヤーはENCHトークンの報酬を受け取るチャンスがあります。",
    ru: "Будет использоваться внутри игры, каждый игрок имеет шанс получить награду в токене ENCH.",
    skor: "게임 내에서 사용되며, 각 플레이어는 ENCH 토큰으로 보상을 받을 기회가 있습니다.",
    uae: "سيتم استخدامه داخل اللعبة، كل لاعب لديه فرصة للحصول على مكافأة في رموز ENCH.",
  },
  tokenomicsInvestors: {
    ch: "将出售给参与预售的投资者。",
    en: "Will be sold to investors participating in the presale.",
    ge: "Wird an Investoren verkauft, die am Presale teilnehmen.",
    jpn: "プレセールに参加する投資家に販売されます。",
    ru: "Будет продано инвесторам, которые участвуют в presale.",
    skor: "프리세일에 참여하는 투자자에게 판매됩니다.",
    uae: "سيتم بيعه للمستثمرين المشاركين في البيع المسبق.",
  },
  tokenomicsTeam: {
    ch: "将用于游戏和平台的进一步发展，我们计划在一个网络中启动多个项目。",
    en: "Will be used for further development of the game and the platform as a whole. We plan to launch several projects within one network.",
    ge: "Wird für die weitere Entwicklung des Spiels und der Plattform insgesamt verwendet. Wir planen, mehrere Projekte innerhalb eines Netzwerks zu starten.",
    jpn: "ゲームおよびプラットフォーム全体のさらなる開発に使用されます。私たちは1つのネットワーク内で複数のプロジェクトを立ち上げる計画です。",
    ru: "Будет использоваться для дальнейшего развития игры и платформы в целом. Мы планируем запускать несколько проектов в одной сети.",
    skor: "게임 및 플랫폼 전체의 추가 개발에 사용됩니다. 우리는 하나의 네트워크 내에서 여러 프로젝트를 시작할 계획입니다.",
    uae: "سيتم استخدامه لمزيد من تطوير اللعبة والمنصة ككل. نخطط لإطلاق عدة مشاريع ضمن شبكة واحدة.",
  },
  totalAmoutTon: {
    ch: "售出的物品总金额",
    en: "Total amount of items sold",
    ge: "Gesamtbetrag der verkauften Artikel",
    jpn: "販売されたアイテムの総額",
    ru: "Предметов продано на сумму",
    skor: "판매된 아이템의 총 금액",
    uae: "إجمالي قيمة العناصر المباعة",
  },
  tradeDescription: {
    ch: "物品交易的主要货币是TON加密货币，<span>销售的20%佣金将分配给加密货币持有者。</span> 剩余部分玩家立即收到并可以随时转移到自己的账户而无需支付任何费用。",
    en: "The main currency for item trading is the TON cryptocurrency. <span>20% of the sales commission will be distributed among cryptocurrency holders.</span> The remaining amount is instantly credited to the player's account and can be transferred to their account at any time without fees.",
    ge: "Die Hauptwährung für den Handel mit Gegenständen ist die TON-Kryptowährung. <span>20% der Verkaufsprovision werden unter den Inhabern der Kryptowährung verteilt.</span> Der verbleibende Betrag wird sofort dem Konto des Spielers gutgeschrieben und kann jederzeit ohne Gebühren auf sein Konto übertragen werden.",
    jpn: "アイテム取引の主要通貨はTON暗号通貨です。<span>販売手数料の20％は暗号通貨の保有者に分配されます。</span> 残りの金額は即座にプレイヤーのアカウントにクレジットされ、手数料なしでいつでもアカウントに転送できます。",
    ru: "Основной валютой обмена предметами является криптовалюта TON. <span>20% комиссии за продажу будет распределяться между держателями криптовалюты.</span> Остальную часть игрок моментально получает на свой счет и имеет возможность в любой момент времени перевести её на свой счет без комиссий.",
    skor: "아이템 거래의 주요 통화는 TON 암호화폐입니다. <span>판매 수수료의 20%는 암호화폐 보유자에게 분배됩니다.</span> 나머지 금액은 즉시 플레이어의 계정에 입금되며 수수료 없이 언제든지 자신의 계정으로 이체할 수 있습니다.",
    uae: "العملة الرئيسية لتداول العناصر هي عملة TON المشفرة. <span>سيتم توزيع 20٪ من عمولة المبيعات بين حاملي العملات المشفرة.</span> يتم إضافة المبلغ المتبقي على الفور إلى حساب اللاعب ويمكن تحويله إلى حسابه في أي وقت بدون رسوم.",
  },
  tradeTitle: {
    ch: "交易",
    en: "Trade",
    ge: "Handel",
    jpn: "取引",
    ru: "Торговля",
    skor: "거래",
    uae: "تجارة",
  },
};

export default DICTIONARY;
