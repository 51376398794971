import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
import styles from "./TokenShare.module.scss";
import Translate from "../base/Translate/Translate";

function AnimatedSVG({ isActive }: any) {
  const paths = [
    {
      className: styles.blue,
      description: "description_airdrop_rewards",
      isLeft: false,
      path: "M220,220 L295,60 L335,60",
      title: "5%",
      transform: "30 -110",
      transformTo: "30 -90",
    },
    {
      className: styles.yellow,
      description: "description_presale_investors",
      isLeft: true,
      path: "M5,220 L-80,60 L-120,60",

      title: "25%",
      transform: "-270 -110",
      transformTo: "-270 -90",
    },
    {
      className: styles.green,
      description: "description_in_game_rewards",
      isLeft: true,
      path: "M5,280 L-80,410 L-120,410",
      title: "30%",
      transform: "-270 -110",
      transformTo: "-270 -90",
    },
    {
      className: styles.red,
      description: "description_future_development",
      isLeft: false,
      path: "M220,280 L295,410 L335,410",
      title: "40%",
      transform: "30 -110",
      transformTo: "30 -90",
    },
  ];
  useEffect(() => {
    const groups = document.querySelectorAll(".group-of-animate");
    if (!isActive || !groups || !groups.length) {
      return;
    }

    groups.forEach((groupEl: any) => {
      const circleAnimateMotion = groupEl.querySelector(
        ".circle-animate-motion",
      );
      const path = groupEl.querySelector(".path");
      const lineLength = path.getTotalLength();

      path.style.strokeDasharray = lineLength + " " + lineLength;
      path.style.strokeDashoffset = "0";
      groupEl.style.opacity = 0;
      const svgCirclePath = circleAnimateMotion.getAttribute("path");
      circleAnimateMotion.setAttribute("path", svgCirclePath);

      setTimeout(() => {
        groupEl.style.opacity = 1;

        path.animate(
          [
            {
              strokeDashoffset: lineLength,
            },
            {
              strokeDashoffset: "0",
            },
          ],
          {
            duration: 1500,
            easing: "linear",
          },
        );

        circleAnimateMotion.beginElement();
      }, 0);
    });
  }, [isActive]);

  // Если isActive false, ничего не рендерится
  if (!isActive) {
    return null;
  }

  return (
    <>
      <svg
        className={`${styles.TokenSharePotionContentAnimate}`}
        height={"420"}
        style={{ overflow: "visible", position: "absolute" }}
        viewBox="0 0 222 420"
        width={"222"}
        xmlns="http://www.w3.org/2000/svg"
      >
        {paths.map(
          (
            {
              className,
              description,
              isLeft,
              path,
              title,
              transform,
              transformTo,
            },
            index,
          ) => (
            <g key={index} className="group-of-animate">
              <linearGradient id="grad1">
                <stop offset="0%" stopColor="yellow" />
                <stop offset="100%" stopColor="red" />
              </linearGradient>

              <path
                d={path}
                fill="none"
                opacity={0}
                stroke="url(#grad1)"
                strokeDasharray="10"
                strokeMiterlimit="10"
                strokeWidth="1"
              >
                <animate
                  attributeName="stroke-dashoffset"
                  begin="2.6s"
                  calcMode="linear"
                  dur="3.5s"
                  repeatCount="indefinite"
                  values="100;0"
                />
                <animate
                  attributeName="opacity"
                  begin="2.6s"
                  dur="0.8s"
                  fill="freeze"
                  to="1"
                />
              </path>

              <path
                className={"path"}
                d={path}
                opacity={0}
                stroke="url(#grad1)"
                strokeDasharray={"3"}
                style={{
                  fill: "none",
                  strokeWidth: "2",
                }}
              >
                <animate
                  attributeName="stroke-dashoffset"
                  begin="0s"
                  dur="1.5s"
                  fill="freeze"
                  to="0"
                />
                <animate
                  attributeName="opacity"
                  begin="0"
                  dur="1.5s"
                  fill="freeze"
                  repeatCount="1"
                  values="0.5;0.25;0.5;0.25;0.5;"
                />
                <animate
                  attributeName="opacity"
                  begin="1.5s"
                  dur="0.95s"
                  fill="freeze"
                  to="0"
                />
              </path>

              <circle
                className="circle"
                r="7"
                stroke="url(#grad1)"
                style={{
                  fill: "#141414",
                  opacity: "0",
                  strokeWidth: "2",
                }}
              >
                <animateMotion
                  begin="0"
                  className="circle-animate-motion"
                  dur="1.5s"
                  fill="freeze"
                  path={path}
                  repeatCount="1"
                />
                <animate
                  attributeName="opacity"
                  begin="0s"
                  dur="0.1s"
                  fill="freeze"
                  to="0.75"
                />
                <animate
                  attributeName="r"
                  begin="1.5s"
                  dur="0.3s"
                  fill="freeze"
                  to="15"
                />
                <animate
                  attributeName="r"
                  begin="1.8s"
                  dur="0.3s"
                  fill="freeze"
                  to="7"
                />
                <animate
                  attributeName="opacity"
                  begin="2s"
                  dur="0.3s"
                  fill="freeze"
                  to="1"
                />
              </circle>

              <g
                fill="#eee"
                height={200}
                opacity={0}
                transform={`translate(${transform})`}
                width={300}
              >
                <foreignObject height="260" width="240">
                  <div className={`${styles.svgTitle}`}>
                    <div
                      className={`${styles.svgTitlePercent} mb-2 ${className} ${
                        isLeft ? "text-end" : ""
                      }`}
                    >
                      {title}
                    </div>
                    <div className={styles.svgTitleDescription}>
                      <Translate dictKey={description} />
                    </div>
                  </div>
                </foreignObject>
                <animateTransform
                  attributeName="transform"
                  begin="2.1s"
                  dur="0.35s"
                  fill="freeze"
                  repeatCount="1"
                  to={`${transformTo}`}
                  type="translate"
                />

                <animate
                  attributeName="opacity"
                  begin="2.1s"
                  dur="0.8s"
                  fill="freeze"
                  to="1"
                />
                <animateMotion
                  begin="0"
                  dur="0.1s"
                  fill="freeze"
                  path={path}
                  repeatCount="1"
                />
              </g>
            </g>
          ),
        )}
      </svg>
      <div className={styles.TokenSharePotionContentAnimateMobile}>
        {paths.map(
          (
            {
              className,
              description,
              isLeft,
              path,
              title,
              transform,
              transformTo,
            },
            index,
          ) => {
            return (
              <div key={index} className={`${styles.svgTitle}`}>
                <div className={`${styles.svgTitlePercent} mb-2 ${className}`}>
                  {title}
                </div>
                <div className={styles.svgTitleDescription}>{description}</div>
              </div>
            );
          },
        )}
      </div>
    </>
  );
}

export default AnimatedSVG;
