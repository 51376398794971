import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { ReactComponent as Logo } from "./../../Logo.svg";
import { ReactComponent as CHLang } from "./../../images/flags/ch.svg";
import { ReactComponent as ENLang } from "./../../images/flags/en.svg";
import { ReactComponent as RULang } from "./../../images/flags/ru.svg";
import { ReactComponent as UAELang } from "./../../images/flags/uae.svg";
import { ReactComponent as JpnLang } from "./../../images/flags/jpn.svg";
import { ReactComponent as SkorLang } from "./../../images/flags/skor.svg";
import useLang from "../../hooks/lang";
import Translate from "../base/Translate/Translate";

const LANGRUAGES_SVG: any = {
  ch: { component: <CHLang />, lang: "中国" },
  en: { component: <ENLang />, lang: "English" },
  jpn: { component: <JpnLang />, lang: "日本語" },
  ru: { component: <RULang />, lang: "Русский" },
  skor: { component: <SkorLang />, lang: "한국인" },
  uae: { component: <UAELang />, lang: "عرب" },
};

function LangComponent() {
  const [lang, setLang] = useLang<string>("lang", "en");
  const [isShowLang, changeIsShowLang] = useState(false);

  return (
    <li className="nav-item dropdown me-4 my-sm-4 my-lg-0">
      <a
        aria-expanded={isShowLang}
        className="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="javascript: void(0)"
        onClick={e => {
          e.preventDefault();
          changeIsShowLang(!isShowLang);
        }}
        role="button"
      >
        <span className={`${styles.Flag} me-2`}>
          {LANGRUAGES_SVG[lang].component}
        </span>
        {LANGRUAGES_SVG[lang].lang}
      </a>
      <ul className={`dropdown-menu ${isShowLang ? "show" : ""}`} id="dropdown">
        {Object.keys(LANGRUAGES_SVG).map(lang => (
          <li key={lang}>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                setLang(lang);
                // changeLanguage(lang);
                changeIsShowLang(false);
              }}
            >
              <span className={`${styles.Flag} me-2`}>
                {LANGRUAGES_SVG[lang].component}
              </span>
              {LANGRUAGES_SVG[lang].lang}
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
}

function Header() {
  const [showMenu, changeShowMenu] = useState(false);
  // const description = useDictionary("pvpArenaBossesDescription");

  const links = [
    { name: "about", url: "#information" },
    { name: "interface", url: "#levels" },
    { name: "demo", url: "#video" },
    { name: "tokenomics", url: "#token-share" },
    { name: "roadmap", url: "#road-map" },
  ];

  return (
    <nav className={`navbar navbar-expand-lg ${styles.Header}`}>
      <div className="container">
        <a className="navbar-brand" href="#">
          <Logo />
        </a>

        <button
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-bs-target="#navbarNav"
          data-bs-toggle="collapse"
          onClick={() => {
            changeShowMenu(!showMenu);
          }}
          type="button"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${showMenu ? "show" : ""}`}>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {links.map((link: any, index: number) => (
              <li key={index} className="nav-item">
                <a className="nav-link" href={link.url}>
                  <Translate dictKey={link.name} />
                </a>
              </li>
            ))}
          </ul>
          <div className="d-flex">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <LangComponent />
              <li className="nav-item my-sm-4 my-lg-0">
                <a
                  className="btn btn-outline-light"
                  href="https://t.me/enchantedworld_bot"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Translate dictKey="startGame" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
