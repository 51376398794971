import React from "react";
import "./App.scss";
import Header from "./components/Header/Header";
import TopScreen from "./components/TopScreen/TopScreen";
import Information from "./components/Information/Information";
import Levels from "./components/Levels/Levels";
import Video from "./components/Video/Video";
import Play from "./components/Play/Play";
import TokenShare from "./components/TokenShare/TokenShare";
import LatestActions from "./components/LatestActions/LatestActions";
import Fotter from "./components/Fotter/Fotter";

function App() {
  return (
    <div data-bs-theme="dark">
      <Header />
      <TopScreen />
      <Information />
      <Levels />
      <Video />
      <Play />
      <TokenShare />
      <Fotter />
    </div>
  );
}

export default App;
