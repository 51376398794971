import React, { ReactEventHandler, useState } from "react";
import styles from "./Levels.module.scss";
import Title from "../base/Title";
import Translate from "../base/Translate/Translate";

function Levels() {
  return (
    <div className={styles.Levels} id="levels">
      <div className={`container ${styles.LevelsContainer}`}>
        <Title title={<Translate dictKey={"interface"} />} />
        <div className={`${styles.LevelsInfo}`}>
          <div className={`${styles.LevelsPhone}`}>
            <video
              autoPlay={true}
              controls={false}
              loop={true}
              muted={true}
              src={process.env.PUBLIC_URL + "/assets/phone.mp4"}
            />
          </div>
          <div className={`${styles.LevelsIsland}`}></div>
          <div className={`${styles.LevelsCastle}`}></div>
          <div>
            <div className={`${styles.LevelsItemInfoLeft}`}>
              <div className={`${styles.LevelsItemInfoIcon}`}></div>
              <div className={`${styles.LevelsItemInfoDescription}`}>
                <Translate dictKey={"interfaceDescTwo"} />
              </div>
            </div>
            <div className={`${styles.LevelsItemInfoRight}`}>
              <div className={`${styles.LevelsItemInfoIcon}`}></div>
              <div className={`${styles.LevelsItemInfoDescription}`}>
                <Translate dictKey={"interfaceDescOne"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Levels;
