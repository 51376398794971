import useLang from "../../../hooks/lang";
import DICTIONARY from "../../../services/dictionary";
type Keys = keyof typeof DICTIONARY;

interface ITranslate {
  dictKey: Keys;
  asInnerHtml?: boolean;
}

function Translate({ asInnerHtml = false, dictKey }: ITranslate) {
  const [lang] = useLang<Keys>("lang", "en");

  if (!dictKey || !DICTIONARY[dictKey] || !DICTIONARY[dictKey][lang]) {
    return <>{"Not found KEY:"}</>;
  }

  if (asInnerHtml)
    return (
      <div
        dangerouslySetInnerHTML={{ __html: DICTIONARY[dictKey][lang] }}
      ></div>
    );
  return <>{DICTIONARY[dictKey][lang]}</>;
}

export default Translate;
