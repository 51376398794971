import React, { ReactEventHandler, useState } from "react";
import styles from "./Information.module.scss";
import Title from "../base/Title";
import Translate from "../base/Translate/Translate";
import { JSX } from "react/jsx-runtime";
import ReactDOMServer from "react-dom/server";

const BLOCKS = [
  {
    description: "adventureDescription",
    icon: styles.defender,
    isBig: false,
    name: "adventure",
  },
  {
    description: "playerAttrDescription",
    icon: styles.mob,
    isBig: true,
    name: "playerAttr",
  },
  {
    description: "pvpArenaBossesDescription",
    icon: styles.skeleton,
    isBig: true,
    name: "pvpArenaBosses",
  },
  {
    description: "tradeDescription",
    icon: styles.witcher,
    isBig: false,
    name: "tradeTitle",
  },
];

function Information() {
  function renderToString(arg0: JSX.Element) {
    throw new Error("Function not implemented.");
  }

  return (
    <div className={styles.Information} id="information">
      <div className={`container ${styles.InformationContainer}`}>
        <Title title={<Translate dictKey="about" />} />
        <div className={`${styles.InformationList}`}>
          {BLOCKS.map(
            ({ description, icon, isBig, name }: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className={`${
                    styles.InformationItem
                  } text-center text-white ${
                    isBig ? styles.InformationItemBig : ""
                  }`}
                >
                  <h2 className={`${styles.InformationItemTitle}`}>
                    <Translate dictKey={name} />
                  </h2>
                  <div
                    className={`${styles.InformationItemIcon} ${icon}`}
                  ></div>
                  <div className={`${styles.InformationItemDescription}`}>
                    <Translate asInnerHtml={true} dictKey={description} />
                  </div>
                </div>
              );
            },
          )}
        </div>
        <div className={`${styles.InformationItemLast}`}>
          <h2 className={`${styles.InformationItemTitle}`}>
            <Translate dictKey={"dividendAndTokenInfoTitle"} />
          </h2>
          <div className={`${styles.InformationItemIcon}`}></div>
          <div className={`${styles.InformationItemDescription}`}>
            <Translate
              asInnerHtml={true}
              dictKey={"dividendAndTokenInfoDescription"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
