import React, { ReactEventHandler, useState } from "react";
import styles from "./Video.module.scss";
import Title from "../base/Title";
import Translate from "../base/Translate/Translate";

function Video() {
  return (
    <div className={styles.Video} id="video">
      <div className={`container ${styles.VideoContainer}`}>
        <Title title={<Translate dictKey={"demo"} />} />
        <div
          className="d-flex align-items-center justify-content-center mt-5 rounded"
          style={{
            background: "#2e2e2e",
            color: "#8e8e8e",
            fontSize: "6em",
            height: "700px",
            textAlign: "center",
            textShadow: "0 1px 1px #222",
          }}
        >
          Coming soon
          {/* <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder="0"
            height="700"
            referrerPolicy="strict-origin-when-cross-origin"
            src="https://www.youtube.com/embed/b1Pj5v7CTCA?si=GKZEYH6dM7g_el2g&amp;controls=0"
            title="YouTube video player"
            width="1100"
          ></iframe> */}
        </div>
      </div>
    </div>
  );
}

export default Video;
