import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
import styles from "./TokenShare.module.scss";
import Title from "../base/Title";
import AnimatedSVG from "./AnimateShare";
import Roadmap from "./RoadMap";
import LatestActions from "../LatestActions/LatestActions";
import Translate from "../base/Translate/Translate";

function TokenShare() {
  const tokenShareRef: any = useRef(null);
  const RoadMapRef: any = useRef(null);
  const [isActiveShare, changeIsActiveShare]: any = useState(false);
  const [isActiveRoadmap, changeIsActiveRoadMap]: any = useState(false);

  useEffect(() => {
    function handleRefScroll(ref: any, changeIsActive: any) {
      if (ref.current) {
        const { bottom, top } = ref.current.getBoundingClientRect();
        changeIsActive(top < window.innerHeight && bottom >= 0);
      }
    }

    function handleScroll() {
      handleRefScroll(tokenShareRef, changeIsActiveShare);
      handleRefScroll(RoadMapRef, changeIsActiveRoadMap);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.TokenShare} id="token-share">
      <div className={`container ${styles.TokenShareContainer}`}>
        <div
          ref={tokenShareRef}
          className={`${styles.TokenSharePotionCtx} mb-5`}
        >
          <Title title={<Translate dictKey={"tokenomics"} />} />
          <div className={`${styles.TokenSharePotionContent}`}>
            <div className={styles.TokenSharePotion}></div>
            <AnimatedSVG isActive={isActiveShare} />
          </div>
        </div>

        <div
          ref={RoadMapRef}
          className={`${styles.RoadMapContent} mt-5`}
          id="road-map"
        >
          <div>
            <Title title={<Translate dictKey={"roadmap"} />} />
          </div>
          <div className="mt-5">
            <Roadmap isActive={isActiveRoadmap} />
          </div>
        </div>

        {/* <div className={`${styles.ActionContent} mt-5`}>
          <div>
            <Title title="Latest operations" />
          </div>
          <div className="mt-5">
            <LatestActions />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default TokenShare;
