import React, { ReactEventHandler, useState } from "react";
import styles from "./Play.module.scss";
import Title from "../base/Title";
import Translate from "../base/Translate/Translate";

function Play() {
  return (
    <div className={styles.Play} id="play">
      <div className={`container ${styles.PlayContainer}`}>
        <div className={styles.PlayInnerContainer}>
          <div className={styles.PlayNpc}></div>
          <div className={styles.PlayTitleAndBtn}>
            <h2 className={styles.PlayTitle}>
              <Translate dictKey={"startPlayNowText"} />
            </h2>
            <button
              className={`btn btn-main-outline px-5 ${styles.PlayBtn}`}
              onClick={() => {
                location.href = "https://t.me/enchantedworld_bot";
              }}
            >
              <Translate dictKey={"startGame"} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Play;
